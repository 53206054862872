body,
html,
#root,
.App {
  width: 100vw;
  overflow: auto;
  /* Allow for scrolling */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center children horizontally */
}

.leaflet-container {
  height: 1000px;
  /* Fixed height */
  width: 1200px;
  /* Fixed width */
  margin-top: 20px;
  /* Space from the top of the page */
  margin-bottom: 20px;
  /* Space between map and table */
}

.leaflet-tile-pane {
  background-color: #ebebeb;
}

table {
  width: 95%;
  margin: 20px auto;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px 12px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

/* Dark theme */
body, html {
  background-color: #121212;
  color: #e0e0e0;
}

.title-container {
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: center;
}

.app-title {
  font-size: 2.5em;
  margin-bottom: 10px;
  color: #FFD700; /* Gold color for title */
}

.app-description {
  font-size: 1.2em;
}

.leaflet-container {
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

a {
  color: #FFD700;
  text-decoration: none;
  transition: all 0.3s ease;
}

a:hover {
  text-decoration: underline;
}

